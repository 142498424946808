import { Rule } from 'async-validator';

export const wteEventStartedAt: Rule = [
  { type: 'string', required: true, message: '請設定活動走期' }
];
export const wteEventEndedAt: Rule = [
  { type: 'string', required: true, message: '請設定活動走期' }
];
export const wteEnrollEndedAt: Rule = [
  { type: 'string', required: true, message: '請設定報名結束時間' }
];
export const wteSuggestEndedAt: Rule = [
  { type: 'string', required: true, message: '請設定建議結束時間' }
];
export const wteVoteEndedAt: Rule = [
  { type: 'string', required: true, message: '請設定投票結束時間' }
];
export const wteEnrollPrize: Rule = [
  { type: 'number', required: true, message: '請設定參加獎比例' }
];
export const wteVotePrize: Rule = [
  { type: 'number', required: true, message: '請設定投票獎比例' }
];
export const wteOfficialPrize: Rule = [
  { type: 'number', required: true, message: '請設定官方加碼比例' }
];
export const wterankingPrizes: Rule = [
  {
    type: 'array',
    required: true,
    len: 3,
    fields: {
      0: {
        type: 'object',
        required: true,
        message: '請設定產品類獎金比例',
        fields: {
          type: { type: 'string', required: true },
          rank: {
            type: 'object',
            required: true,
            fields: {
              first: { type: 'number', required: true },
              second: { type: 'number', required: true },
              third: { type: 'number', required: true }
            }
          }
        }
      },
      1: {
        type: 'object',
        required: true,
        message: '請設定內容類獎金比例',
        fields: {
          type: { type: 'string', required: true },
          rank: {
            type: 'object',
            required: true,
            fields: {
              first: { type: 'number', required: true },
              second: { type: 'number', required: true },
              third: { type: 'number', required: true }
            }
          }
        }
      },
      2: {
        type: 'object',
        required: true,
        message: '請設定演員類獎金比例',
        fields: {
          type: { type: 'string', required: true },
          rank: {
            type: 'object',
            required: true,
            fields: {
              first: { type: 'number', required: true },
              second: { type: 'number', required: true },
              third: { type: 'number', required: true }
            }
          }
        }
      }
    }
  }
];
