
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import EventDetail from './components/EventDetail.vue';
import { useWteEvent } from '@/composables/api';

export default defineComponent({
  components: { EventDetail },
  setup() {
    const route = useRoute();

    const eventId = computed<string>(() => route.params.id as string);

    const { data } = useWteEvent({ eventId: eventId.value });

    return {
      route,
      data
    };
  }
});
