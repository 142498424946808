
import { defineComponent, ref, watch } from 'vue';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { useEventsSuggestion } from '@/composables/api';
import { EventSuggestionStatus } from '@/interfaces';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute, useRouter } from 'vue-router';
import { usePage } from '@/composables/page';
import { useKeyword } from '@/composables';
import { DEFAULT_PAGE_SIZE } from '@/services/api';

const statusText = {
  verifying: {
    text: '待審核'
  },
  voting: {
    text: '進行中'
  },
  finished: {
    text: '已結束'
  }
};

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: '狀態',
    placeholder: '請選擇',
    options: [
      {
        label: '待審核',
        value: `${EventSuggestionStatus.VERIFYING}`
      },
      {
        label: '進行中',
        value: `${EventSuggestionStatus.VOTING}`
      },
      {
        label: '已結束',
        value: `${EventSuggestionStatus.FINISHED}`
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const eventDateRange = ref(null);
    const eventStartedAt = ref('');
    const eventEndedAt = ref('');
    const status = ref('');
    const statusIndex = ref(undefined);

    const { data, isLoading, isFetching } = useEventsSuggestion({ page, pageSize, keyword, eventId: keyword, eventStartedAt, eventEndedAt, status });

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
    };

    const handleDateChange = () => {
      if (!eventDateRange.value) {
        eventStartedAt.value = '';
        eventEndedAt.value = '';
        return;
      }
      eventStartedAt.value = formatISOString(eventDateRange.value[0]);
      eventEndedAt.value = formatISOString(eventDateRange.value[1]);
    };

    watch([eventStartedAt, eventEndedAt], ([eventStartedAt, eventEndedAt]) => {
      router.push({ query: { ...route.query, eventId: keyword.value, eventStartedAt, eventEndedAt } });
    });

    watch(
      () => route.query,
      (query) => {
        eventStartedAt.value = query.eventStartedAt as string;
        eventEndedAt.value = query.eventEndedAt as string;
        if (eventStartedAt.value && eventEndedAt.value) {
          eventDateRange.value = [eventStartedAt.value, eventEndedAt.value];
          return;
        }
        eventDateRange.value = null;
      },
      { immediate: true }
    );

    return {
      page,
      data,
      isLoading,
      isFetching,
      statusIndex,
      keyword,
      FILTER_OPTIONS,
      eventDateRange,
      formatLocalTime,
      handleDateChange,
      handleFilterChange,
      setPageQuery,
      statusText
    };
  }
});
