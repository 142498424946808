
import dayjs from 'dayjs';
import { ElMessage } from 'element-plus';
import { RankingPrize, WteEvent } from '@/interfaces/WTE';
import { computed, defineComponent, PropType, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { formatLocalTime } from '@/utils/format-time';
import { isNil, cloneDeep, pick, isEmpty, includes, forEach, omit, map } from 'lodash';
import { validate } from '@/utils/asyncValidate';
import { createWteEvent, ResponseError, updateWteEvent } from '@/services/api';
import {
  wteEventStartedAt,
  wteEventEndedAt,
  wteSuggestEndedAt,
  wteVoteEndedAt,
  wteEnrollEndedAt,
  wteOfficialPrize,
  wterankingPrizes
} from '@/utils/rules';

type ComponentType = 'edit' | 'create' | 'show'

const rules = {
  eventStartedAt: wteEventStartedAt,
  eventEndedAt: wteEventEndedAt,
  enrollEndedAt: wteEnrollEndedAt,
  suggestEndedAt: wteSuggestEndedAt,
  voteEndedAt: wteVoteEndedAt,
  officialPrize: wteOfficialPrize,
  rankingPrizes: wterankingPrizes
};

const ISO_START_OF_KEY = [
  'eventStartedAt',
  'eventEndedAt'
];

const ISO_END_OF_KEY = [
  'enrollEndedAt',
  'suggestEndedAt',
  'voteEndedAt'
];

const NUMBER_KEY = [
  'enrollPrize',
  'votePrize',
  'officialPrize'
];

const DEFAULT_FORM_OBJ: WteEvent = {
  eventStartedAt: '',
  eventEndedAt: '',
  enrollEndedAt: '',
  suggestEndedAt: '',
  voteEndedAt: '',
  enrollPrize: 0,
  votePrize: 0,
  officialPrize: 0,
  rankingPrizes: [
    {
      type: 'product',
      rank: {
        first: 0,
        second: 0,
        third: 0
      }
    },
    {
      type: 'content',
      rank: {
        first: 0,
        second: 0,
        third: 0
      }
    },
    {
      type: 'actor',
      rank: {
        first: 0,
        second: 0,
        third: 0
      }
    }
  ],
  status: undefined
};

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<WteEvent>,
      default: () => ({})
    },
    type: {
      type: String as PropType<ComponentType>,
      required: true
    }
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { type, data } = toRefs(props);
    const dateRange = ref(null);
    const defaultForm = ref<WteEvent>(cloneDeep(DEFAULT_FORM_OBJ));

    const eventId = computed<string>(() => route.params.id as string);
    const canModify = computed(() => type.value !== 'show');
    const formattedData = computed(() => [defaultForm.value]);

    const clearDateRange = () => {
      dateRange.value = null;
      defaultForm.value.enrollEndedAt = '';
      defaultForm.value.suggestEndedAt = '';
      defaultForm.value.eventStartedAt = '';
      defaultForm.value.eventEndedAt = '';
      defaultForm.value.voteEndedAt = '';
    };

    const handleDateChange = () => {
      if (isNil(dateRange.value)) {
        clearDateRange();
        return;
      }

      const [startDate, endDate] = dateRange.value;
      const isDateRangeLowerToWeek = dayjs(endDate).diff(startDate, 'day') < 6;

      if (isDateRangeLowerToWeek) {
        clearDateRange();
        ElMessage.error('活動走期區間最少 7 日');
        return;
      }

      defaultForm.value.eventStartedAt = dayjs(startDate).startOf('day').toISOString();
      defaultForm.value.eventEndedAt = dayjs(endDate).startOf('day').toISOString();
      defaultForm.value.voteEndedAt = dayjs(endDate).subtract(1, 'day').endOf('day').toISOString();
      defaultForm.value.enrollEndedAt = '';
      defaultForm.value.suggestEndedAt = '';
    };

    const disabledEnrollEndedAt = (datesOnCalendar: any) => {
      const startDateTimestamp = dayjs(defaultForm.value.eventStartedAt).valueOf();
      const endDateTimestamp = dayjs(defaultForm.value.eventEndedAt).subtract(4, 'day').valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;
      const afterEndDate = datesTimestamp > endDateTimestamp;

      return beforeStartDate || afterEndDate;
    };

    const disabledSuggestEndedAt = (datesOnCalendar: any) => {
      const startDateTimestamp = dayjs(defaultForm.value.enrollEndedAt).add(1, 'day').valueOf();
      const endDateTimestamp = dayjs(defaultForm.value.eventEndedAt).subtract(3, 'day').valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;
      const afterEndDate = datesTimestamp > endDateTimestamp;

      return beforeStartDate || afterEndDate;
    };

    const disabledEventRange = (datesOnCalendar: any) => {
      if (process.env.VUE_APP_STAGE !== 'production') return false;

      const startDateTimestamp = dayjs().startOf('day').valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;

      return beforeStartDate;
    };

    const getFormatFormToFitApi = (data: WteEvent) => {
      const form = cloneDeep(data);

      forEach(form, (column, key) => {
        if (includes(ISO_START_OF_KEY, key)) {
          form[key] = !column ? '' : dayjs(column as string).startOf('day').toISOString();
        };
        if (includes(ISO_END_OF_KEY, key)) {
          form[key] = !column ? '' : dayjs(column as string).endOf('day').startOf('second').toISOString();
        };
        if (includes(NUMBER_KEY, key)) {
          form[key] = Number(form[key]);
        }
        if (key === 'rankingPrizes') {
          form[key] = map(column as RankingPrize[], (item) => {
            const rank = item.rank;

            forEach(rank, (rk, key) => {
              rank[key] = Number(rk);
            });

            return { ...item, rank };
          });
        }
      });

      return form;
    };

    const handleValidate = async(): Promise<boolean> => {
      const descriptor = rules;
      const form = getFormatFormToFitApi(defaultForm.value);

      const { errors } = await validate(descriptor, form);

      // 驗證通過
      if (!errors) {
        return true;
      }
      // 驗證失敗
      ElMessage.error(errors[0].message);
      return false;
    };

    const submitForm = async() => {
      if (!handleValidate()) return;

      try {
        let data;

        if (type.value === 'create') {
          data = await createWteEvent({ data: omit(getFormatFormToFitApi(defaultForm.value), ['status']) });
        }

        if (type.value === 'edit') {
          data = await updateWteEvent({ eventId: eventId.value, data: omit(getFormatFormToFitApi(defaultForm.value), ['status']) });
        }

        router.go(-1);
      } catch (err) {
        const error = err as ResponseError;
        ElMessage.error(error.response?.data.message);
      }
    };

    const assignDataToForm = (data: WteEvent) => {
      const formattedData = omit(getFormatFormToFitApi(data), ['createdAt', 'updatedAt']);

      defaultForm.value = {
        ...defaultForm.value,
        ...formattedData
      };

      dateRange.value = [defaultForm.value.eventStartedAt, defaultForm.value.eventEndedAt];
    };

    if (includes(['edit', 'show'], type.value)) {
      if (!isEmpty(data)) {
        assignDataToForm(data.value);
      }
      watch(data, (newData) => {
        assignDataToForm(newData);
      });
    }

    return {
      dayjs,
      dateRange,
      defaultForm,
      canModify,
      formattedData,
      handleDateChange,
      disabledEnrollEndedAt,
      disabledSuggestEndedAt,
      disabledEventRange,
      formatLocalTime,
      handleValidate,
      submitForm
    };
  }
});
